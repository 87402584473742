<template>
  <v-container v-if="detail">
    <v-row>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Previsto</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Previsto }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle
                >Valor previsto de bonificação</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="blue"
                >mdi-chart-bell-curve-cumulative</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Ganho</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Ganho }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle
                >Valor de bonificação ganha</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="green"
                >mdi-cash-plus</v-icon
              ></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Debitos</div>
              <v-list-item-title class="headline mb-1">
                <h2 class="text-muted">R$ {{ detail.Debito || 0 }}</h2>
              </v-list-item-title>
              <v-list-item-subtitle>Debito de bonificação</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar tile>
              <v-icon :size="50" color="red"
                >mdi-cash-minus
              </v-icon></v-list-item-avatar
            >
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" md="2" class="mobile">
        <v-card outlined height="100%">
          <v-card-title primary-title> Meta Geral </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <apexchart
                  type="radialBar"
                  v-if="radial"
                  :options="radial.chartOptions"
                  :series="radial.series"
                ></apexchart>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                ><h1 v-if="metaGeral" class="text-center">
                  {{ metaGeral.venda_geral }} / {{ metaGeral.meta_geral }}
                </h1></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4" class="mobile" v-if="detail" md="4">
        <v-card outlined>
          <v-card-title primary-title> Bonificações </v-card-title>
          <v-card-text>
            <graphBonus
              :ganho="detail.Ganha"
              :pendente="detail.Pendente"
              :cancelado="detail.Cancelada"
              :key="salePerson"
            />
          </v-card-text>
        </v-card>
      </v-col>

    <v-col cols="4" md="5" class="mobile">
        <v-card outlined>
          <v-card-title primary-title> Tecnologias </v-card-title>
          <v-card-text>
            <apexchart
              type="pie"
              data
              :options="graph"
              :series="series"
              
            ></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { formatDate, getProfile } from "../../mixins";

import graphBonus from "./graphBonus";

export default {
  name: "dashCoord",
  props: ["seller"],
  mixins: [formatDate, getProfile],
  components: {
    graphBonus,
  },
  data: () => {
    return {
      salePerson: null,
      tech: {
        graph: {
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                },
              },
            },
          },
          chart: {
            width: 380,
            type: "donut",
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          labels: [],
        },
        series: [],
      },
            graph: {
        chart: {
          width: 380,
          type: "pie",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: []

      },
      series: [],
      meta: null,
      detail: null,
      sales: "",
      loaded: false,
      radial: null,
      options: {
        colors: ["#57B45E", "#F44336"],

        xaxis: {
          categories: [],
        },
      },
      dados: [
        {
          name: "Vendas Efetuadas",

          data: [],
        },
        {
          name: "Vendas Canceladas",

          data: [],
        },
      ],
      headers: [
        { text: "Vendedor", value: "ref_nome_vendedor" },
        { text: "Cliente", value: "ref_nome_cliente" },
        { text: "Tecnologia", value: "ref_tipo_valida" },
        { text: "Instação", value: "taxa" },
        { text: "Data da Venda", value: "ref_data_venda" },
        { text: "Valor bonificação", value: "valor" },
        { text: "Bonificação", value: "status" },
        { text: "Contrato", value: "ref_v_stage_contrato" },
      ],
    };
  },

  async created() {
    await this.getProfile();
    if (this.seller) {
      this.salePerson = this.seller;
    }

    this.getMeta();
    this.getSalesDetail();
    this.getTech();
  },

  methods: {


  async getMeta() {
      const { data } = await this.$http.get("saleschart?chart=goalteams");
      this.metaGeral = data;
      this.radial = {
        series: [((this.metaGeral.venda_geral / this.metaGeral.meta_geral) * 100).toFixed(2)],
        chartOptions: {
          chart: {
            type: "radialBar",
          },

          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
            },
          },
          labels: ["Total"],
        },
      };
    },
    async getSalesDetail() {
      try {
        const result = await this.$http.get(
          `/sales/coord/${this.salePerson}/detail`
        );
        this.detail = result.data;
      } catch (error) {
        this.$toast.error("Detalhes de venda não localizados!");
      }
    },
         async getTech(){
      const { data } = await this.$http.get("saleschart?chart=tech");

      data.forEach((d) => {
        this.graph.labels.push(d.ref_tipo_valida);

        this.series.push(
          d.count
        );
      });
    },
  },
};
</script>
<style>
h2 {
  font-size: 1.875rem;
}

.text-muted {
  color: #98a6ad !important;
}

@media only screen and (max-width: 37.5em) {
  .mobile {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}
</style>